<template>
  <a-layout style="padding: 0px; height: 100%; overflow: auto">
    <a-layout-content
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 60px;
      "
    >
      <div class="Enter-top">
        <div>
          <a-breadcrumb style="margin: 18px 0">
            <a-breadcrumb-item>巡检管理</a-breadcrumb-item>
            <a-breadcrumb-item>巡检点位</a-breadcrumb-item>
          </a-breadcrumb>
        </div>
        <div class="flow-d-row" style="float: right; align-items: center">
          <a-button>
            <a @click="onexcelAll">下载所有二维码</a>
          </a-button>
          <a-button
            style="margin-left: 15px"
            type="primary"
            @click="showDrawer"
          >
            新增点位
          </a-button>
        </div>
      </div>
    </a-layout-content>
    <a-layout style="padding: 24px; height: 100%; overflow: auto">
      <a-layout-content
        style="
          background-color: #fff;
          line-height: 60px;
          margin: 0;
          min-width: 1330px;
          width: 100%;
        "
      >
        <a-form
          style="padding: 0 24px"
          class="ant-advanced-search-form"
          :form="fom"
          @submit="handleSearch"
        >
          <a-row class="flex-d-row" style="flex-wrap: wrap" :gutter="24">
            <a-col v-for="(i, index) in textlist" :key="index">
              <a-form-item style="margin-bottom: 0" :label="i">
                <a-select
                  v-if="i == '楼栋'"
                  style="width: 173.5px"
                  placeholder="请选择楼栋"
                  @change="onbuildtop"
                  v-model="params['cps.building_id'].value"
                >
                  <a-select-option v-for="item in buildlist" :key="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
                <a-select
                  v-else-if="i == '楼层'"
                  style="width: 173.5px"
                  placeholder="请选择楼栋"
                  v-model="params['cps.floor_id'].value"
                >
                  <a-select-option v-for="item in floorlist" :key="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
                <a-select
                  v-model="params['cps.state'].value"
                  v-else-if="i == '状态'"
                  placeholder="请选择状态"
                  style="width: 173.5px"
                >
                  <a-select-option value=""> 全部 </a-select-option>
                  <a-select-option value="0"> 启用 </a-select-option>
                  <a-select-option value="1"> 禁用 </a-select-option>
                </a-select>
                <a-input
                  v-else
                  style="width: 173.5px"
                  v-decorator="[
                    `${i}`,
                    {
                      rules: [],
                    },
                  ]"
                  :placeholder="'请输入' + i"
                />
              </a-form-item>
            </a-col>
            <a-col class="a-col-top">
              <a-button type="primary" html-type="submit"> 搜索 </a-button>
              <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
                清空
              </a-button>
            </a-col>
          </a-row>
        </a-form>
      </a-layout-content>

      <a-layout-content
        style="background: #fff; padding: 24px; margin: 0; min-width: 1330px"
      >
        <a-modal
          :ok-button-props="{ props: { disabled: true } }"
          :cancel-button-props="{ props: { disabled: true } }"
          maskClosable="true"
          title="下载二维码"
          v-model="savevisible"
        >
          <div id="home">
            <div class="meng">
              <a-icon style="fontsize: 20px" type="loading" /><span
                style="margin-left: 5px"
              >
                正在生成二维码...</span
              >
            </div>
            <div
              id="printTests"
              ref="printTests"
              class="flow-d-column item"
              style="
                margin: auto;
                width: 149px;
                height: 220px;
                background-color: #1890ff;
                align-items: center;
                padding-bottom: 10px;
                border-radius: 5px;
                overflow: hidden;
                border: 1px solid #d9d9d9;
              "
            >
              <div class="hu-a" style="">
                <span
                  v-for="item in qrname"
                  :key="item"
                  style="margin-right: 1px"
                  >{{ item }}</span
                >
              </div>
              <div
                class="flow-d-column"
                style="
                  width: 105px;
                  flex: 1;
                  justify-content: center;
                  align-items: center;
                "
              >
                <div
                  class="flow-d-column"
                  style="
                    width: 100px;
                    height: 100px;
                    background-color: #fff;
                    justify-content: center;
                    align-items: center;
                    border-radius: 8px;
                  "
                >
                  <img
                    style="width: 95px; height: 95px"
                    :src="QRlinks"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </a-modal>
        <!-- 表格 -->
        <div class="search-wrap">
          <a-table
            id="cesdsad"
            :row-selection="rowSelection"
            rowKey="id"
            :pagination="false"
            childrenColumnName="child"
            :columns="columns"
            :data-source="patrollist"
            :rowClassName="rowClassName"
          >
            <span slot="status" slot-scope="text, record">
              <a-switch
                :checked="text == 0 ? true : false"
                default-checked
                @change="onchangestate(record)"
              />
            </span>
            <span style="font-family: '黑体'" slot="code" slot-scope="text"
              >{{ text }}
            </span>
            <a slot="qr" slot-scope="text, record">
              <a-popover placement="left" trigger="click">
                <template slot="content">
                  <div>
                    <div
                      id="printTest"
                      ref="printTest"
                      class="flow-d-column"
                      style="
                        width: 150px;
                        height: 220px;
                        background-color: #1890ff;
                        align-items: center;
                        margin: -12px -16px;
                        padding-bottom: 10px;
                        border-radius: 5px;
                        overflow: hidden;
                        border: 1px solid #d9d9d9;
                      "
                    >
                      <div class="hu-a">
                        {{ record.name | text }}
                      </div>
                      <div
                        class="flow-d-column"
                        style="
                          width: 105px;
                          flex: 1;
                          justify-content: center;
                          align-items: center;
                        "
                      >
                        <div
                          class="flow-d-column"
                          style="
                            width: 100px;
                            height: 100px;
                            background-color: #fff;
                            justify-content: center;
                            align-items: center;
                            border-radius: 8px;
                          "
                        >
                          <img
                            style="width: 95px; height: 95px"
                            :src="QRlink"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <a @click="QRImgUrl(record.qrCode)"> 查看&nbsp;&nbsp; </a>
              </a-popover>
              <a @click="onexcel(record)">下载</a>
            </a>
            <template slot="action" slot-scope="text, record">
              <a @click="onEdit(record)">编辑</a>&nbsp;&nbsp;
              <a-popconfirm
                title="你确认要删除该点位么?"
                ok-text="是"
                cancel-text="否"
                @confirm="onDetele(record)"
                @cancel="cancel"
              >
                <a>删除</a> </a-popconfirm
              >&nbsp;&nbsp;
              <a @click="onprint(record)" v-print="'#printTests'">打印</a>
            </template>
          </a-table>
          <a-pagination
            v-model="params.current"
            :page-size="params.pageSize"
            show-quick-jumper
            :default-current="1"
            :total="total"
            @change="onpagesize"
          />
          <a-modal
            :title="title"
            :visible="visible"
            width="600px"
            @ok="onSubmit"
            @cancel="onClose"
          >
            <a-form-model
              ref="ruleForm"
              :rules="rules"
              :model="form"
              :label-col="labelCol"
              :wrapper-col="wrapperCol"
            >
              <a-form-model-item ref="name" prop="name" label="巡检点位名称">
                <a-input
                  @blur="
                    () => {
                      $refs.name.onFieldBlur()
                    }
                  "
                  v-model="form.name"
                />
              </a-form-model-item>
              <a-form-model-item label="楼栋">
                <a-select
                  @change="onbuilds"
                  style="width: 100%"
                  v-model="form.buildingId"
                >
                  <a-select-option v-for="item in buildlist" :key="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="楼层">
                <a-select style="width: 100%" v-model="form.floorId">
                  <a-select-option v-for="item in floorlist" :key="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item ref="place" prop="place" label="安装位置">
                <a-input
                  @blur="
                    () => {
                      $refs.name.onFieldBlur()
                    }
                  "
                  v-model="form.place"
                />
              </a-form-model-item>
            </a-form-model>
          </a-modal>
        </div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
const columns = [
  {
    title: '楼栋',
    dataIndex: 'building.name',
  },
  {
    title: '楼层',
    dataIndex: 'floor.name',
  },
  {
    title: '点位编号',
    dataIndex: 'code',
    scopedSlots: { customRender: 'code' },
  },
  {
    title: '点位名称',
    dataIndex: 'name',
  },
  {
    title: '安装位置',
    dataIndex: 'place',
  },
  {
    title: '状态',
    dataIndex: 'state',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '点位二维码',
    dataIndex: 'qrCode',
    scopedSlots: { customRender: 'qr' },
  },
  {
    title: '操作',
    dataIndex: 'id',
    scopedSlots: { customRender: 'action' },
  },
]
// const statusMap = {
//   0: {
//     status: "warning",
//     text: "草稿",
//   },
//   1: {
//     status: "processing",
//     text: "提交审核",
//   },
//   2: {
//     status: "success",
//     text: "审核通过",
//   },
//   3: {
//     status: "error",
//     text: "审核拒绝",
//   },
// // };
// function getBase64(file) {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => resolve(reader.result);
//     reader.onerror = (error) => reject(error);
//   });
// }
// const OSS = require("ali-oss");
// let client;
import JSZip from 'jszip'
import FileSaver from 'file-saver'
// import { nanoid } from "nanoid";
import QRCode from 'qrcode'
import moment from 'moment'
import http from '../../../http'
import html2canvas from 'html2canvas'
export default {
  inject: ['reload'],
  data() {
    return {
      qrname: [],
      savevisible: false,
      QRlinks: '',
      indexs: 0,
      demoList: [],
      qrFile: [],
      columns,
      visible: false,
      selectedRowKey: [],
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      title: '',
      textlist: ['楼栋', '楼层', '点位编号', '点位名称', '安装位置', '状态'],
      expand: false,
      fom: this.$form.createForm(this, { name: 'advanced_search' }),
      value: '',
      params: {
        current: 1,
        pageSize: 10,
        'cps.code': {
          value: '',
          op: '%like%',
        },
        'cps.place': {
          value: '',
          op: '%like%',
        },
        'cps.name': {
          value: '',
          op: '%like%',
        },
        'cps.building_id': {
          value: undefined,
          op: '=',
        },
        'cps.floor_id': {
          value: undefined,
          op: '=',
        },
        'cps.state': {
          value: undefined,
          op: '=',
        },
      },
      total: 1,
      buildlist: [],
      floorlist: [],
      Id: '',
      patrollist: [],
      QRlink: '',
      form: {
        id: '',
        code: '',
        name: '',
        place: '',
        projectId: '',
        buildingId: '',
        state: '',
        floorId: '',
      },
      qcname: '',
      arrImages: [],
      copyDom: [],
      e: '',
      rules: {
        buildingId: [
          {
            required: true,
            message: '请选择楼栋',
            trigger: 'change',
          },
        ],
        floorId: [
          {
            required: true,
            message: '请选择楼层',
            trigger: 'change',
          },
        ],
        name: [
          {
            required: true,
            message: '巡检点位名称不能为空且长度不能超过9个字符',
            trigger: 'blur',
            max: 9,
          },
        ],
        place: [
          {
            required: true,
            message: '安装位置不能为空',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  filters: {},
  methods: {
    onprint(e) {
      this.savevisible = true
      QRCode.toDataURL(
        e.qrCode,
        {
          errorCorrectionLevel: 'L',
          margin: 2,
          width: 128,
        },
        (err, url) => {
          if (err) throw err
          this.QRlinks = url
          this.qrname = e.name
        }
      )
      setTimeout(() => {
        this.savevisible = false
      }, 100)
    },
    removedom() {
      this.copyDom.forEach(item => {
        document.body.removeChild(item)
      })
      this.savevisible = false
    },
    chquname(e) {
      this.qcname = e
      this.qrname = e.split('')
    },
    download() {
      let options = {
        useCORS: true,
        backgroundColor: null,
        dpi: window.devicePixelRatio * 8,
        scale: 4,
      }
      var arrImages = []
      this.copyDom = []
      this.QRlinks = this.qrFile[0].url
      this.chquname(this.qrFile[0].name)
      let index = 0
      let myseInterval = setInterval(() => {
        this.QRlinks = this.qrFile[index].url
        this.chquname(this.qrFile[index].name)
        const targetDoms = document.getElementById('printTests')
        if (index == this.qrFile.length - 1) {
          clearInterval(myseInterval)
        }
        setTimeout(() => {
          let copyDom = targetDoms.cloneNode(true)
          copyDom.style.width = targetDoms.clientWidth
          copyDom.style.height = targetDoms.clientHeight
          this.copyDom.push(copyDom)
          document.querySelector('body').appendChild(copyDom)
          html2canvas(copyDom, options).then(canvas => {
            let imgURL = canvas.toDataURL('image/png')
            //   console.log(i, imgURL);
            arrImages.push({
              fileUrl: imgURL,
              renameFileName: this.qcname + '.png',
            })
            if (index == this.qrFile.length) {
              //   console.log("图片包", arrImages);
              this.filesToRar(arrImages, '二维码压缩包')
              this.qrFile = []
              this.removedom()
            }
          })
        }, 100)
        index += 1
      }, 1300)
    },
    /**文件打包
     * arrImages:文件list:[{fileUrl:文件url,renameFileName:文件名}]
     * filename 压缩包名
     * */
    filesToRar(arrImages, filename) {
      let _this = this
      let zip = new JSZip()
      let cache = {}
      let promises = []
      _this.title = '正在加载压缩文件'

      for (let item of arrImages) {
        const promise = _this.getImgArrayBuffer(item.fileUrl).then(data => {
          // 下载文件, 并存成ArrayBuffer对象(blob)
          zip.file(item.renameFileName, data, { binary: true }) // 逐个添加文件
          // zip.file(item.renameFileName, data, {base64: true}); // 逐个添加文件
          cache[item.renameFileName] = data
        })
        promises.push(promise)
      }

      // let img = zip.folder("images");
      // arrImages.forEach((v,i)=>{
      //   img.file(v.renameFileName, v.fileUrl, {base64: true})
      // })

      Promise.all(promises)
        .then(() => {
          zip.generateAsync({ type: 'blob' }).then(content => {
            _this.title = '正在压缩'
            // 生成二进制流
            FileSaver.saveAs(content, filename) // 利用file-saver保存文件  自定义文件名
            _this.title = '压缩完成'
          })
        })
        .catch(() => {
          //   console.log(res);
          this.$message.error('文件压缩失败')
        })
    },
    //获取文件blob
    getImgArrayBuffer(url) {
      return new Promise((resolve, reject) => {
        //通过请求获取文件blob格式
        let xmlhttp = new XMLHttpRequest()
        xmlhttp.open('GET', url, true)
        xmlhttp.responseType = 'blob'
        xmlhttp.onload = function () {
          if (this.status == 200) {
            resolve(this.response)
          } else {
            reject(this.status)
          }
        }
        xmlhttp.send()
      })
    },

    QRImgUrl(callRecord) {
      QRCode.toDataURL(
        callRecord,
        {
          errorCorrectionLevel: 'L',
          margin: 2,
          width: 128,
        },
        (err, url) => {
          if (err) throw err
          this.QRlink = url
        }
      )
    },
    handleSearch(e) {
      e.preventDefault()
      this.params.current = 1
      this.fom.validateFields((error, values) => {
        // console.log(values);
        this.params['cps.name'].value = values.点位名称
        this.params['cps.code'].value = values.点位编号
        this.params['cps.place'].value = values.安装位置
      })
      5
      this.getpatrol()
    },
    handleReset() {
      this.fom.resetFields()
      this.params['cps.building_id'].value = undefined
      this.params['cps.floor_id'].value = undefined
      this.params['cps.state'].value = undefined
    },

    toggle() {
      this.expand = !this.expand
    },
    callback(e) {
      if (e == undefined) {
        this.keys = '-1'
      } else {
        this.keys = e
      }
      this.params.current = 1
      this.params['cb.bill_date'].value = ''
      this.params['cb.bill_date#2'].value = ''
      this.params['cb.pay_date'].value = ''
      this.params['cb.pay_date#2'].value = ''
      this.params['cb.state'].value = ''
      this.params['cb.confirm'].value = ''
      if (e == 1) {
        const startDate = moment()
          .month(moment().month())
          .startOf('month')
          .format('YYYY-MM-DD')
        const endDate = moment()
          .month(moment().month())
          .endOf('month')
          .format('YYYY-MM-DD')
        this.params['cb.bill_date'].value = startDate
        this.params['cb.bill_date#2'].value = endDate
      } else if (e == 2) {
        this.params['cb.state'].value = '1'
        this.params['cb.confirm'].value = '0'
        this.params['cb.pay_date'].value = moment().format('YYYY-MM-DD')
      } else if (e == 3) {
        this.params['cb.state'].value = '1'
        this.params['cb.confirm'].value = '0'
        this.params['cb.pay_date#2'].value = moment()
          .subtract(1, 'days')
          .format('YYYY-MM-DD')
      }
      this.getPaymentInfo()
    },

    onClose() {
      this.visible = false
      this.form = {
        id: '',
        code: '',
        name: '',
        place: '',
        projectId: '',
        buildingId: '',
        state: '',
        floorId: '',
      }
    },
    onSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          //   console.log("submit!", this.form);
          if (this.form.id == '') {
            this.Addpatrolsite()
          } else {
            this.putpatrolsite()
          }
        } else {
          return false
        }
      })
    },
    onchangestate(e) {
      //   console.log(e);
      if (e.state == 1) {
        this.form.state = 0
      } else if (e.state == 0) {
        this.form.state = 1
      }
      this.getpatrolsitestate(e.id, this.form.state)
    },
    async getpatrol() {
      try {
        const res = await http.getpatrol(this.params)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.patrollist = data.sites.records
          this.total = data.sites.total
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    // 删除
    onDetele(e) {
      this.deletepatrolsite([e.id])
    },
    async deletepatrolsite(id) {
      try {
        const res = await http.deletepatrolsite(id)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('提交成功')
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        this.$message.error(ex)
        console.log('error:' + ex)
      }
    },
    // 编辑
    onEdit(item) {
      this.form.id = item.id
      this.title = '编辑'
      this.visible = true
      this.getpatrolsiteId(item.id)
    },
    onpagesize(e) {
      this.params.current = e
      this.getpatrol()
    },
    showDrawer() {
      this.visible = true
      this.buildingid(this.form.projectId)
      this.title = '新增'
      this.$store.commit('selectKeys')
    },
    onbuildtop(e) {
      this.floorid(e)
    },
    onbuilds() {
      this.floorid(this.form.buildingId)
    },
    onexcel(e) {
      this.savevisible = true
      QRCode.toDataURL(
        e.qrCode,
        {
          errorCorrectionLevel: 'L',
          margin: 2,
          width: 128,
        },
        (err, url) => {
          if (err) throw err
          this.QRlinks = url
          this.chquname(e.name)
        }
      )
      setTimeout(() => {
        html2canvas(document.getElementById('printTests'), {
          dpi: window.devicePixelRatio * 8,
          scale: 4,
          useCORS: true,
          backgroundColor: null,
        }).then(canvas => {
          let imgUri = canvas.toDataURL('image/png')
          FileSaver.saveAs(imgUri, this.qcname)
        })
      }, 300)
    },
    onexcelAll() {
      this.savevisible = true
      this.getpatrolqrAll()
    },
    async getpatrolqrAll() {
      try {
        const res = await http.getpatrolqrAll()
        const { success, data } = res.data
        if (success) {
          if (data.sites) {
            //遍历数据
            data.sites.forEach(v => {
              QRCode.toDataURL(
                v.qrCode,
                {
                  errorCorrectionLevel: 'L',
                  margin: 2,
                  width: 128,
                },
                (err, url) => {
                  if (err) throw err
                  this.qrFile.push({
                    name: v.name,
                    url: url,
                  })
                }
              )
            })
            this.download()
            // this.getUrl();
          } else {
            this.$message.error('当前没有可下载的二维码')
          }
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async qepatrol(id) {
      try {
        const res = await http.qepatrol(id)
        const { success, data } = res.data
        if (success) {
          console.log(data)
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    //新增
    async Addpatrolsite() {
      try {
        const res = await http.Addpatrolsite(this.form)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('提交成功')
          this.onClose()
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    //修改
    async putpatrolsite() {
      try {
        const res = await http.putpatrolsite(this.form)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('提交成功')
          this.onClose()
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async getpatrolsiteId(id) {
      try {
        const res = await http.getpatrolsiteId(id)
        const { success, data } = res.data
        if (success) {
          this.form.buildingId = data.site.buildingId
          this.form.place = data.site.place
          this.form.floorId = data.site.floorId
          this.form.name = data.site.name
          this.form.code = data.site.code
          this.buildingid(data.site.projectId)
          this.floorid(data.site.buildingId)
          //   console.log(data);
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    //状态
    async getpatrolsitestate(id, state) {
      try {
        const res = await http.getpatrolsitestate(id, state)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('提交成功')
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        this.$message.error(ex)
        console.log('error:' + ex)
      }
    },
    async buildingid(projectId) {
      try {
        const res = await http.buildingid(projectId)
        const { success, data } = res.data
        if (success) {
          this.buildlist = data.buildings
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async floorid(buildingId) {
      try {
        const res = await http.floorid(buildingId)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.floorlist = data.floors
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    timer() {
      return setTimeout(() => {
        this.getpatrol()
      }, 100)
    },
    rowClassName(record, index) {
      let className = 'light'
      if (index % 2 === 1) className = 'dark'
      return className
    },
  },

  mounted() {
    this.getpatrol()
    let users = JSON.parse(localStorage.getItem('store-userlist'))
    this.form.projectId = users.projectInfo.projectId
    this.buildingid(this.form.projectId)
    if (this.$route.query.visible == 'true') {
      this.showDrawer()
    }
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKey, selectedRows) => {
          this.selectedRowKey = selectedRowKey
          console.log(selectedRows)
        },
      }
    },
  },
}
</script>
<style scoped>
.search-wrap {
  width: 100%;
  border-radius: 3px;
  position: relative;
  min-height: 600px;
  height: 100%;
  padding: 10px 0 6px;
}
.ant-upload-text {
  font-size: 13px;
}
.ant-pagination {
  float: right;
  margin-top: 10px;
}
.flex-d-row {
  display: flex;
  flex-direction: row;
}
.ant-layout {
  height: 100%;
}
.flow-d-column {
  display: flex;
  flex-direction: column;
}
.icon-url {
  position: absolute;
  right: -25px;
  font-size: 18px;
  top: 0px;
  cursor: pointer;
}
.ant-advanced-search-form .ant-form-item {
  height: 80px;
  margin-bottom: 15px;
}
.ant-form-item {
  height: 50px;
  margin-bottom: 15px;
}
.a-col-top {
  position: absolute;
  right: 0;
  top: 43px;
}
.Enter-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
}
.a-col-span {
  display: inline-block;
  width: 24px;
  text-align: center;
}
::v-deep .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px;
  padding-left: 10px;
}
.ant-input {
  border-radius: 0;
  padding-left: 10px;
  color: #000;
}
::v-deep .ant-form-item-label {
  text-align: right;
}
::v-deep .ant-table .light {
  background-color: white;
}
::v-deep .ant-table .dark {
  background-color: #fafafa;
}
::v-deep .ant-popover-inner-content {
  padding: 0;
}
::v-deep .ant-upload-list-picture-card .ant-upload-list-item,
::v-deep .ant-upload-select-picture-card,
::v-deep .ant-upload-list-picture-card-container {
  width: 70px;
  height: 70px;
}
.ant-calendar-picker-input.ant-input {
  border-radius: 0;
}
.item {
  width: 150px;
  height: 220px;
  background-color: #1890ff;
  justify-content: center;
  align-items: center;
  margin: -12px -16px;
  padding-bottom: 10px;
}
.meng {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
}
.hu-a {
  height: 60px;
  font-size: 15px;
  line-height: 50px;
  text-align: center;
  color: #000;
  background-color: #fff;
  font-weight: bold;
  width: 110%;
  border-radius: 0 0 100% 100%;
}
</style>
